<template>
  <section class="newDetails">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <div class="title">
        <h1 v-if="options.title">{{ options.title }}</h1>
        <p class="tit">
          <span v-if="options.source">来源:{{ options.source }}</span>
          <span v-if="options.author">作者：{{ options.author }}</span>
          <span v-if="options.date">日期：{{ options.date }}</span>
        </p>
      </div>
      <ArticleContent :data="options.content" class="information-content" />
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/新闻资讯.png')
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      if ( id ) {
        switch ( id ) {
          case '1':
            this.options = this.$store.getters.getCompanyNew1 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew1))
            break
          case '2':
            this.options = this.$store.getters.getCompanyNew2 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew2))
            break
          case '3':
            this.options = this.$store.getters.getCompanyNew3 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew3))
            break
          case '4':
            this.options = this.$store.getters.getCompanyNew4 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew4))
            break
          case '5':
            this.options = this.$store.getters.getCompanyNew5 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew5))
            break
          case '6':
            this.options = this.$store.getters.getCompanyNew7 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew6))
            break
          case '7':
            this.options = this.$store.getters.getCompanyNew7 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew7))
            break
          case '11':
            this.options = this.$store.getters.getCompanyNew11 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew11))
            break
          case '12':
            this.options = this.$store.getters.getCompanyNew12 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew12))
            break
          case '13':
            this.options = this.$store.getters.getCompanyNew13 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew13))
            break
          case '14':
            this.options = this.$store.getters.getCompanyNew14 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew14))
            break
          case '15':
            this.options = this.$store.getters.getCompanyNew15 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew15))
            break
          case '16':
            this.options = this.$store.getters.getCompanyNew16 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew16))
            break
          case '17':
            this.options = this.$store.getters.getCompanyNew17 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew17))
            break
          case '18':
            this.options = this.$store.getters.getCompanyNew18 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew18))
            break
          case '19':
            this.options = this.$store.getters.getCompanyNew19 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew19))
            break
          case '20':
            this.options = this.$store.getters.getCompanyNew20 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew20))
            break
          case '21':
            this.options = this.$store.getters.getCompanyNew21 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew21))
            break
          case '22':
            this.options = this.$store.getters.getCompanyNew22 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew22))
            break
          case '23':
            this.options = this.$store.getters.getCompanyNew23 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew23))
            break
          case '24':
            this.options = this.$store.getters.getCompanyNew24 && JSON.parse(JSON.stringify(this.$store.getters.getCompanyNew24))
            break
          default:
            this.options = this.$store.getters.getCompanyNew1
        }
      }
    },
  }
}
</script>

<style scoped>
.newDetails {background-color: #ecf0f5; padding-bottom: 1rem;}
.newDetails .container {padding: 1rem 10%;}
.title {text-align: center; line-height: 2; padding: 1rem;}
</style>